html, body{
    margin:0;
    padding:0
}
.layershide{
  display: none;
}

.layersshow{
  display: block;
}
.main {
    display: flex;
    min-height: 100vh
}
.sidebarWrapper {
    display:flex;
    flex-direction: column;
    flex:1;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 6px, rgba(0, 0, 0, 0.07) 0px 1px 4px;
    background: #3c3c3c;
    position: relative;
    min-width: 250px;
}
.previewWrapper{
    display:flex;
    flex-direction: column;
    flex:5; 
}
.previewContainer{
    display: flex;
    flex:content;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.previewControls{
    flex:content;
    border-top: 1px solid #ddd;
    position:  relative;
    background: black;
}
.controls{
    display:flex;
}
.controls > div:not(:nth-child(6)):hover{
    cursor: pointer;
    color: gray
}
.controls > div {
    flex: content;
    margin-right: 20px;
}
.controls > div:nth-child(6) > div:first-child{
    padding-top:3px
}
.controls > div:nth-child(6) > div{
    flex:content
}
.controls > div:nth-child(6){
    display: flex;
    flex-grow: 1;
    width: 100%
}
.controls > div:last-child{
    width:40px;
    height:30px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px 1px 6px, rgba(0, 0, 0, 0.17) 0px 1px 4px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
}
.controls-slider{
    flex-grow: 1;
    padding-top:2px;
    margin-left:20px;
    width:100%;
}
#preview {
    width:500px;
    height:500px;
}
.parsedColorsWrapper ul {
    margin:0;
    padding:0;
}
.parsedColorsWrapper h4 {
    background: black;
    color: white;
    padding: 14px;
    margin: 0px;
    border-bottom: 2px solid;
}
.parsedColorsWrapper{
    width:100%;
    flex:content;
    background: #fafafa;
    margin-top: -350px;
}
.colorPicker {
    flex:content;  
}
.parsedColorItem li {
    list-style-type: none;
    cursor: pointer;
}
.layerRoot {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-top: 0.04em solid rgba(0,0,0,0.08);
    color: white;
    text-transform: capitalize;
    font-weight: bold;
}
.layerRoot > div{
    flex:1;
    cursor: pointer;
}
.playArrowDown{
    transform: rotate(90deg); 
    display:inline-block;
}
.background-color-picker {
    position: absolute;
    right: -230px;
    bottom: 180px;
    z-index: 999;
}
#github-link {
    position: absolute;
    right:10px;
    top:10px;
    text-decoration: none
}
#github-link p{
    display: inline
}
.drop-files-wrapper {
    border-radius: 10px;
    width: 800px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    text-transform: uppercase;
    font-size: 18px;
}
.drop-files-wrapper >div {
    margin-left: 10px;
}
.fetch-error{
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:-50px;
    left:0;
    width: 100%;
}
.fetch-error > span{
    background: rgba(0,0,0,0.35);
    padding: 10px 20px;
    border-radius: 5px;
    color:#f3f3f3
}
.button {
    backface-visibility: hidden;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: linear-gradient(90deg,#1d953f 0%,#209542 20.343056921228012%,#4dde76 20.343056921228012%,#4dde76 100%);
    border-radius: 5px;
    border: 0px solid #444444;
    border-width: 0px 0px 0px 0px;
    padding:10px 20px 10px 50px;
    color: #ffffff;
    font-size: 16px;
    font-family: arial;
    font-weight: 100;
    font-style: normal
}
.button > div {
    color: #999999;
    font-size: 10px;
    font-family: Helvetica Neue;
    font-weight: initial;
    font-style: normal;
    text-align: center;
    margin: 0px 0px 0px 0px
}
.button > span {
    color: #6ec286;
    font-size: 1em;
    background: #ffffff;
    border-radius: 88px;
    border: 0px solid #6ec286;
    border-width: 0px 0px 0px 0px;
    padding: 4px 4px 4px 4px;
    margin: 0px 10px 0px 0px;
    position: absolute;
    top: 9px;
    left: 17px;
    width: 16px;
    height: 16px
}
.button > .ld {
    font-size: initial
}
.controls {
    color: white;
}
.colorPicker .sketch-picker {
    width: 92% !important;
}
.sidebarWrapper h4 {
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight: bold;
}
.parsedColorsWrapper ul li {
    background: #030303;
    border-bottom: 1px solid white;
}
.sidebarRight {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 6px, rgba(0, 0, 0, 0.07) 0px 1px 4px;
    background: #3c3c3c;
    position: relative;
    min-width: 250px;  
}
.sidebarRight h3 {
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}
.layerVisibility ul li a {
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.layerVisibility ul {
    padding: 0px;
    text-align: center;
}
.layerVisibility ul li {
    display: inline-block;
    list-style-type: none;
    padding-left: 16px;
}
.restButton {
    text-align: center;
}
.eidtorBackgroundColor {
    margin-top: 20px;
    text-align: center;
    padding: 12px;
    font-weight: bold;
}
.controls.download-sidebarbutton div button {
    color: black;
}
.sidebar-actions div button {
    width: 42%;
    margin-bottom: 12px;
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
}
.sidebar-actions div {
    width: 100%;
}
.sidebar-actions.last {
    padding-top: 0px !important;
}
.controls.download-sidebarbutton div {
    width: 100%;
    margin-right: 0px;
}